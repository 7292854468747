import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const HelmetInfo = ({ is404 }) => {
  const shortTitle = 'Preschool in Vineyard, Utah | Lilypad Preschool';
  const openGraphImage =
    'https://res.cloudinary.com/dewd4pral/image/upload/v1584823046/lilypad/classroom_open_graph_meta.png';
  const metaImage =
    'https://res.cloudinary.com/dewd4pral/image/upload/v1584823092/lilypad/classroom_twitter_meta.png';
  const description =
    'Lilypad Preschool in Vineyard, Utah helps students prepare for kindergarten through hands-on learning, free play, and fine motor skills development. Come learn, play, and grow with us!';
  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      {!is404 && <meta name="ROBOTS" content="INDEX, FOLLOW" />}
      {is404 && <meta name="robots" content="noindex" />}
      <meta name="theme-color" content="#FFFFFF" />
      <meta
        name="keywords"
        content="vineyard utah preschool, preschools near me, best preschool near me, orem utah preschool, lilypad preschool"
      />
      {/* GOOGLE */}
      <meta name="google-site-verification" content="HTP1dYgugtmzSOktYyxM3ayO1heiDhzgQfZ8TBpc-xM" />
      {/* TWIITER CARD */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={shortTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={metaImage} />
      {/* FB/OPEN GRAPH TAGS */}
      <meta property="og:url" content="https://lilypadpreschoolutah.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={shortTitle} />
      <meta property="og:site_name" content="Lilypad Preschool" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={openGraphImage} />
      {/* TITLE / DESC */}
      <title>Preschool in Vineyard, Utah | {is404 ? `404 Not Found` : `Lilypad Preschool`}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

HelmetInfo.defaultProps = {
  is404: false,
};

HelmetInfo.propTypes = {
  is404: PropTypes.bool,
};

export default HelmetInfo;
